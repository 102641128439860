import { css, Global, withTheme } from '@emotion/react'
import * as React from 'react'

const PriceStyles = withTheme(() => (
  <Global
    styles={css`
      /* TODO: CORE FIX */
      .background-color-dark,
      .background-color-Dark,
      .background-color-Black {
        .leshen-price-change * {
          color: #fff !important;
        }
      }

      .leshen-price-change {
        color: #fff !important;
      }

      .leshen-price-amount-wrapper {
        * {
          font-family: 'PP Object Sans' !important;
        }
      }

      .leshen-price-dollar {
        font-size: 24px;
      }
    `}
  />
))

export default PriceStyles
