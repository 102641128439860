module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteURL":"https://www.frontierbundles.com"},
    },{
      plugin: require('../../../node_modules/@leshen/gatsby-theme-leshen/gatsby-browser.js'),
      options: {"plugins":[],"analyticsId":"GTM-5HQSFN","brandTokens":["FTR","FTRB"],"mapiBrandToken":"FTR","siteName":"frontierbundles","alternateName":"frontierbundles.com","siteURL":"https://www.frontierbundles.com","defaultTitleTemplate":"","defaultPhone":"8776499629","phoneSymbol":"-","defaultPromoCode":"23843","smartyStreetsWebsiteKey":"","addressQualificationCode":"","convertProjectID":"10045797","vwoProjectID":"894940","chatEnabled":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"frontierbundles","short_name":"frontierbundles","start_url":"/","background_color":"#ff0037","theme_color":"#ff0037","icon":"src/images/favicon.png","display":"browser","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a71c3250e67b30121836d9753a3f9b44"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
