exports.components = {
  "component---src-templates-business-jsx": () => import("./../../../src/templates/Business.jsx" /* webpackChunkName: "component---src-templates-business-jsx" */),
  "component---src-templates-city-copper-jsx": () => import("./../../../src/templates/CityCopper.jsx" /* webpackChunkName: "component---src-templates-city-copper-jsx" */),
  "component---src-templates-city-fiber-jsx": () => import("./../../../src/templates/CityFiber.jsx" /* webpackChunkName: "component---src-templates-city-fiber-jsx" */),
  "component---src-templates-default-jsx": () => import("./../../../src/templates/Default.jsx" /* webpackChunkName: "component---src-templates-default-jsx" */),
  "component---src-templates-dynamic-zip-packages-jsx": () => import("./../../../src/templates/DynamicZipPackages.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-jsx" */),
  "component---src-templates-dynamic-zip-packages-spanish-jsx": () => import("./../../../src/templates/DynamicZipPackagesSpanish.jsx" /* webpackChunkName: "component---src-templates-dynamic-zip-packages-spanish-jsx" */),
  "component---src-templates-one-trust-privacy-policy-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-jsx" */),
  "component---src-templates-one-trust-privacy-policy-spanish-jsx": () => import("./../../../src/templates/OneTrustPrivacyPolicySpanish.jsx" /* webpackChunkName: "component---src-templates-one-trust-privacy-policy-spanish-jsx" */),
  "component---src-templates-spanish-city-copper-jsx": () => import("./../../../src/templates/SpanishCityCopper.jsx" /* webpackChunkName: "component---src-templates-spanish-city-copper-jsx" */),
  "component---src-templates-spanish-city-fiber-jsx": () => import("./../../../src/templates/SpanishCityFiber.jsx" /* webpackChunkName: "component---src-templates-spanish-city-fiber-jsx" */),
  "component---src-templates-spanish-do-not-sell-form-jsx": () => import("./../../../src/templates/SpanishDoNotSellForm.jsx" /* webpackChunkName: "component---src-templates-spanish-do-not-sell-form-jsx" */),
  "component---src-templates-spanish-jsx": () => import("./../../../src/templates/Spanish.jsx" /* webpackChunkName: "component---src-templates-spanish-jsx" */),
  "component---src-templates-spanish-lp-jsx": () => import("./../../../src/templates/SpanishLp.jsx" /* webpackChunkName: "component---src-templates-spanish-lp-jsx" */),
  "component---src-templates-spanish-state-copper-jsx": () => import("./../../../src/templates/SpanishStateCopper.jsx" /* webpackChunkName: "component---src-templates-spanish-state-copper-jsx" */),
  "component---src-templates-spanish-state-fiber-jsx": () => import("./../../../src/templates/SpanishStateFiber.jsx" /* webpackChunkName: "component---src-templates-spanish-state-fiber-jsx" */),
  "component---src-templates-state-copper-jsx": () => import("./../../../src/templates/StateCopper.jsx" /* webpackChunkName: "component---src-templates-state-copper-jsx" */),
  "component---src-templates-state-fiber-jsx": () => import("./../../../src/templates/StateFiber.jsx" /* webpackChunkName: "component---src-templates-state-fiber-jsx" */)
}

