/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
  This brand theme will get imported into the site theme and the two will be merged using lodash.merge.
*/
module.exports = {
  colors: {
    primary: '#ff0037',
    secondary: '#96fff5',
    tertiary: '#2d3548',
    dark: '#141928',
    light: '#f3f4f4',
    warning: '#ff7928',
    error: '#ff3068',
    success: '#2de6bc',
    background1: '#FFFFFF',
    background2: '#F6F6F6',
    background3: '#000000',
    background4: '#EE0000',
    background5: '#6F7171',
    background6: '#D8DADA',
  },
  gradients: {
    primary: 'linear-gradient(to right, #FF0037, #CC002C, #990021)',
    secondary: 'linear-gradient(to right, #96FFF5, #80D9D0, #70BFB8)',
    tertiary: 'linear-gradient(to right, #FFFFFF, #CFD6ED)',
  },
  backgroundColors: {
    primary: {
      textColor: 'white',
    },
  },
  alternateBgColor: false,
  logos: {
    chat: 'logo-chat.svg',
  },
  fonts: {
    headings: {
      family: 'Bandwidth Display, Helvetica',
    },
    subheadings: {
      family: 'PP Object Sans Bold, Helvetica',
    },
    body: {
      family: 'PP Object Sans, Helvetica',
    },
  },
  components: {
    Button: {
      rounded: 'max',
      variants: {
        primary: {
          color: 'white',
          hoverColor: 'white',
          activeColor: 'white',
        },
      },
    },
    ListItem: {
      icon: 'Done',
    },
    Column: {
      border: {
        stroke: {
          width: '1px',
        },
      },
    },
    PackageCard: {
      backgroundColor: '#f3f4f4',
      radius: '24px',
      shadow: '0',
      stroke: {
        color: 'none',
      },
    },
    Price: {
      duration: {
        // position: 'underPrice', // top, bottom (default), center, underCents, underPrice
        size: '16px',
      },
      dollarSign: {
        size: '24px',
      },
      dollar: {
        size: '24px',
      },
      cents: {
        size: '24px',
      },
    },
  },
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '8px',
}
