import { css, Global, withTheme } from '@emotion/react'

import * as React from 'react'

const FooterStyles = withTheme(() => (
  <Global
    styles={css`
      .leshen-footer {
        .top-section {
          padding-bottom: 30px;
          padding-top: 30px;

          @media (min-width: 940px) {
            padding-bottom: 60px;
            padding-top: 60px;
          }
        }

        .bottom-section {
          padding-top: 30px;
          padding-bottom: 30px;
          background-color: #fff;

          @media (min-width: 940px) {
            padding-top: 60px;
            padding-bottom: 60px;
          }

          .nav {
            p {
              font-weight: 700;
            }
          }
        }
      }
    `}
  />
))

export default FooterStyles
